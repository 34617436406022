<template>
  <div class="x-speaker-card speaker-card">
    <div class="speaker-card-border">
      <div class="x-speaker-card-body speaker-card-body">
        <v-row>
          <v-col cols="auto" align-self="center">
            <div
              class="x-speaker-person-avatar d-flex flex-column align-center justify-content-center"
            >
              <div class="local-divider-coffee-img">
                <v-img style="width: 100%" :src="entertainmentImg"></v-img>
              </div>
            </div>
          </v-col>
          <v-col cols="auto" sm="8" md="4">
            <div class="white--text font-weight-bold text-sm-h5 py-1 mt-10">
              {{ eventTitle }}
            </div>

            <div
              class="white--text mb-2 text-title text-md-title text-bold mb-10"
            >
              {{ eventSubTitle }}
            </div>
            <div
              class="d-none font-weight-light d-sm-block white--text text-caption"
              style="11px !important"
            ></div>
          </v-col>
          <v-col cols="12" class="d-sm-none py-0">
            <div class="white--text text-caption"></div>
          </v-col>
          <v-col cols="12" md="5" class="ms-md-auto d-flex flex-column">
            <div
              class="primary--text d-flex align-center mb-2 text-h6 text-md-h5"
            >
              <div class="me-2">
                <v-icon class="text-h6 text-md-h4" color="primary"
                  >mdi-clock-outline</v-icon
                >
              </div>
              <div>{{ time }}</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import simpelAvatar from "@/assets/user-cyrcle.svg";

import { mapMutations, mapGetters } from "vuex";
import watchVideo from "@/mixins/watchVideo.js";
import SandShowImg from "@/assets/sand-show.svg";
import DrumShowImg from "@/assets/drum-show.svg";

export default {
  name: "x-entertainment-card",
  mixins: [watchVideo],
  props: {
    defaultModel: {
      type: Boolean,
      default: () => false,
    },
    time: {
      type: String,
    },
    avatar: {
      type: String,
      default: () => simpelAvatar,
    },
    imgIndex: { type: Number },

    miniAvatar: {
      type: String,
    },
    personDescription: {
      type: String,
    },

    event: {
      type: String,
    },
    name: {
      type: String,
    },
    position: {
      type: String,
    },
    company: {
      type: String,
    },
    country: {
      type: String,
    },
    eventTitle: {
      type: String,
    },
    eventSubTitle: {
      type: String,
    },
    eventDescription: {
      type: String,
      default: () => "[]",
    },
    video: {
      type: String,
    },
    galleryImgs: {
      type: Array,
    },
    isDrumShow: {
      type: Boolean,
      default: false,
    },
    entertainmentImgProp: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      SandShowImg,
      simpelAvatar,
      defaultModelObj: {
        avatar:
          "https://i.ibb.co/CmJkKkm/101138716e268c39e49b77a158bce102-3.png",
        miniAvatar: "https://i.ibb.co/d6pck8X/Group-162500.png",
        name: "Mr. Taha Khalifa",
        position: "GCC- Country Manager",
        company: "Intel",
        country: "MENA",
        personDescription:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
        time: "15:30 - 15:40",
        eventDescription: [
          "Asset Owners and FMs Requirement.",
          "One of Asia's Largest Public Listed FM company with Operation in Malaysia / Singapore /India /Indonesia /UAE /KSA/ Qatar and Taiwan",
        ],
      },
    };
  },

  computed: {
    ...mapGetters("Db", ["getCurrentChapter"]),
    entertainmentImg() {
      return this.entertainmentImgProp.trim()
        ? this.entertainmentImgProp
        : this.isDrumShow
        ? DrumShowImg
        : SandShowImg;
    },
    getAvatarSize() {
      if (this.$vuetify.breakpoint.name === "xl") return 219;
      if (this.$vuetify.breakpoint.name === "lg") return 219;
      if (this.$vuetify.breakpoint.name === "sm") return 219;
      return 100;
    },
    getMiniAvatarSize() {
      if (this.$vuetify.breakpoint.name === "xl") return 64;
      if (this.$vuetify.breakpoint.name === "lg") return 64;
      if (this.$vuetify.breakpoint.name === "sm") return 64;
      return 30;
    },
    model: {
      get() {
        if (this.defaultModel) return this.defaultModelObj;
        return {};
      },
    },
  },
  methods: {
    ...mapMutations("meet-request-store", ["setDialog", "setPerson"]),
    ...mapMutations("Db", ["showImgGallery", "hideImgGallery"]),

    request() {
      this.setPerson({
        name: this.model.name,
        info: this.model.personDescription,
        job: this.model.position,
        image: this.model.avatar,
      });
      this.setDialog(true);
    },
  },
};
</script>
<style lang="scss">
.x-speaker-person-avatar-model-parent {
  position: relative;
  z-index: 1;
  .x-speaker-person-avatar-model-main-avatar {
    position: relative;
    z-index: 2;
  }
  .x-speaker-person-avatar-model-mini-avatar {
    position: absolute;
    z-index: 3;
    // pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
  }
  .x-speaker-person-avatar-model-mini-avatar-pointer {
    pointer-events: fill;
  }
  .mh-dis-person-avatar-model-mini-avatar-gallery {
    position: absolute;
    z-index: 4;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: start;
    .mh-dis-person-avatar-model-mini-avatar-gallery--pic {
      pointer-events: fill;
      transform: translateX(-21px);
    }
  }
}
</style>
<style lang="scss">
.speaker-card {
  .speaker-card-border {
    border-radius: 14px;
    position: relative;
    z-index: 2;
    &::before {
      position: absolute;
      content: "";
      width: calc(100% + -3px);
      height: calc(100% + -14px);
      left: -3px;
      bottom: -3px;
      z-index: 1;
      background-color: var(--v-primary-base);
      border-radius: 14px;
    }
    .speaker-card-body {
      background-color: var(--v-base-dark-base);
      border-radius: 14px;
      position: relative;
      z-index: 2;

      .avatar-parent {
        background-color: var(--v-base-light-base);

        border-radius: 50%;
      }
      .paragraph-line-height {
        line-height: 27px;
      }
    }
  }
}

.x-speaker-card {
  .x-speaker-card-anonymous {
    pointer-events: none;
    user-select: none;
    filter: blur(12px);
  }
  .x-speaker-card-body {
    padding: 20px;
  }
  .x-speaker-card-avatar {
    padding: 10px;
  }
}
@media (min-width: 600px) {
  .x-speaker-card {
    .x-speaker-card-body {
      padding: 22px;
    }
  }
}
@media (min-width: 960px) {
  .x-speaker-card {
    .mh-speaker-card-avatar {
      padding: 10px;
    }
  }
}
@media (min-width: 1264px) {
}
@media (min-width: 1904px) {
}
</style>
