<template>
    <svg
      width="182"
      height="211"
      viewBox="0 0 182 211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path d="M91.2539 187.052V208.838" stroke="#75B840" />
    <path d="M162.812 64.9129L181.513 54.1689" stroke="#75B840" />
   
    <path
          d="M90.8604 187.057L19 146.288V64.769L90.8604 24L162.72 64.77V146.289L90.8604 187.058V187.057ZM79.8242 118.663C78.636 118.664 77.4965 119.136 76.6562 119.977C75.816 120.817 75.3431 121.956 75.3418 123.144C75.3431 124.332 75.8159 125.471 76.6562 126.31C77.4966 127.15 78.6363 127.622 79.8242 127.622C81.0116 127.621 82.1495 127.149 82.9893 126.309C83.829 125.47 84.3014 124.331 84.3027 123.144C84.3019 121.956 83.8307 120.817 82.9912 119.977C82.1517 119.137 81.0129 118.665 79.8252 118.663H79.8242ZM78.9238 104.047C77.9735 104.048 77.0627 104.426 76.3906 105.098C75.7186 105.77 75.3402 106.681 75.3389 107.631V112.447C75.3473 113.39 75.7293 114.291 76.4014 114.953C77.0735 115.614 77.9808 115.982 78.9238 115.976C79.8593 115.975 80.7566 115.602 81.418 114.941C82.0793 114.279 82.4511 113.382 82.4521 112.447V111.158H95.5518C98.6614 111.146 101.64 109.901 103.833 107.697C106.026 105.492 107.256 102.508 107.252 99.398C107.256 96.2883 106.026 93.304 103.833 91.0995C101.64 88.895 98.6614 87.6502 95.5518 87.638H78.9199C77.9696 87.6391 77.0588 88.017 76.3867 88.6888C75.7147 89.3607 75.3363 90.2717 75.335 91.222C75.3363 92.1723 75.7147 93.0833 76.3867 93.7552C77.0588 94.427 77.9696 94.8049 78.9199 94.806H95.5508C96.7678 94.8084 97.9344 95.293 98.7949 96.1537C99.6554 97.0143 100.139 98.1809 100.142 99.398C100.148 100.623 99.6683 101.8 98.8076 102.672C97.947 103.543 96.7756 104.038 95.5508 104.047H78.9238Z"
          fill="#0E171D"
          stroke="#75B840"
        />
    <path
      d="M19.3057 64.769L91.1657 24L163.026 64.769V146.288L91.1657 187.057L19.3057 146.288V64.769Z"
      stroke="#75B840"
    />
    <path d="M19.7 64.9153L1 54.1763" stroke="#75B840" />
    <path
      d="M1 53.527L91.169 1L181.338 53.527V157.176L91.169 209.235L1 157.176V53.527Z"
      stroke="#75B840"
      stroke-width="1"
    />
    </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>