import { render, staticRenderFns } from "./x-bees-row-component.vue?vue&type=template&id=4fa30060&"
import script from "./x-bees-row-component.vue?vue&type=script&lang=js&"
export * from "./x-bees-row-component.vue?vue&type=script&lang=js&"
import style0 from "./x-bees-row-component.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports