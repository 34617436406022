import Vue from "vue";
import mhSpeakersList from "./mh-speakers-list/mh-speakers-list-component.vue";

import mhDisPerson from "./mh-dis-person/mh-dis-person-component.vue";
import mhGuestPerson from "./mh-guest-person/mh-guest-person-component.vue";

import mhGallery from "./mh-gallery/mh-gallery.vue";

import xBeeEye from "./x-bee-eye/x-bee-eye-component.vue";
import xBee from "./x-bee/x-bee-component.vue";
import xBeesContainer from "./x-bees-container/x-bees-container-component.vue";
import xBeesRow from "./x-bees-row/x-bees-row-component.vue";

import xEntertainmentCard from "./x-entertainment-card/x-entertainment-card-component.vue";
import xSpeakerCard from "./x-speaker-card/x-speaker-card-component.vue";
import xSpeakerSection from "./x-speaker-section/x-speaker-section-component.vue";

import xDisSection from "./x-dis-section/x-dis-section-component.vue";

import xBookRequestDialog from './x-book-request-dialog/x-book-request-dialog-component';
import inviteRequestDialog from "./x-invite-request-dialog/x-invite-request-dialog-component.vue";
Vue.component(mhSpeakersList.name, mhSpeakersList);

Vue.component(mhDisPerson.name, mhDisPerson);
Vue.component(mhGuestPerson.name, mhGuestPerson);
Vue.component(mhGallery.name, mhGallery);

Vue.component(xBeesContainer.name, xBeesContainer);
Vue.component(xBeesRow.name, xBeesRow);
Vue.component(xBee.name, xBee);
Vue.component(xBeeEye.name, xBeeEye);

Vue.component(xSpeakerSection.name, xSpeakerSection);
Vue.component(xSpeakerCard.name, xSpeakerCard);

Vue.component(xEntertainmentCard.name, xEntertainmentCard);

Vue.component(xDisSection.name, xDisSection);

Vue.component(inviteRequestDialog.name, inviteRequestDialog);
Vue.component(xBookRequestDialog.name, xBookRequestDialog);

import VueNumber from "vue-number-animation";

Vue.use(VueNumber);
