import { baseURL } from "@/api/urls";
import axios from "axios";
export default {
  namespaced: true,
  state: {
    loading: true,
    speakers: {},
    dPPeople: {},
    guestList: [],
    headerPeople: {},
    otherDynamicData: {
      partnersList: [],
      partnersHeadLogo: "",
    },
    galleryList: [],
    galleryIndex: 0,
    galleryVisible: false,

    //
    chapters: [
      {
        id: 1,
        name: "CHAPTER ONE",
        "md-title": "MENA Region BIGGEST IoT Conference",
        route: "chapter-1",
        "sheet-url": `${baseURL}/chapter/1`,
        address: {
          line1: "W Dubai - The Palm",
          line2: "Dubai UAE",
        },
        date: "November 22, 2022",
        "tab-title": "Cracking IoT Conference - Disrupt-X | Intel ",
        buttons: [
          {
            text: "CHAPTER TWO",
            label: "June 13, 2023 | Malaysia (APAC)",
            url: "/chapter-2",
          },
          {
            text: "CHAPTER THREE",
            label: "October 13, 2023 | Dubai (MENA)",
            url: "/chapter-3",
          },
          {
            text: "CHAPTER FOUR",
            label: "February 29, 2024 | Riyadh (MENA)",
            url: "/chapter-4",
          },
          {
            text: "CHAPTER Five",
            label: "October 11, 2024 | Dubai",
            url: "/chapter-5",
          },
        ],
      },
      {
        id: 2,
        name: "CHAPTER TWO",
        "md-title": "MENA Region BIGGEST IoT Conference",
        route: "chapter-2",
        "sheet-url": `${baseURL}/chapter/2`,
        address: {
          line1: "KL Convention Center",
          line2: "Kuala Lumpur , Malaysia",
        },
        date: "June 13, 2023",
        "tab-title": "Cracking IoT Conference - Disrupt-X | Intel ",
        buttons: [
          {
            text: "CHAPTER ONE",
            label: "November 22, 2022 |  Dubai (MENA)",
            url: "/chapter-1",
          },
          {
            text: "CHAPTER THREE",
            label: "October 13, 2023 | Dubai (MENA)",
            url: "/chapter-3",
          },
          {
            text: "CHAPTER FOUR",
            label: "February 29, 2024 | Riyadh (MENA)",
            url: "/chapter-4",
          },
          {
            text: "CHAPTER Five",
            label: "October 11, 2024 | Dubai",
            url: "/chapter-5",
          },
        ],
      },
      {
        id: 3,
        name: "CHAPTER THREE",
        "md-title": "MENA Region BIGGEST IoT Conference",
        route: "chapter-3",
        "sheet-url": `${baseURL}/chapter/3`,
        address: {
          line1: "InterContinental - Dubai Festival City",
          line2: "Dubai - UAE",
        },
        date: "October 13, 2023",
        "tab-title": "Cracking IoT Conference - Disrupt-X | Intel ",
        buttons: [
          {
            text: "CHAPTER ONE",
            label: "November 22, 2022 |  Dubai (MENA)",
            url: "/chapter-1",
          },
          {
            text: "cHAPTER TWO",
            label: "June 13, 2023 | Malaysia (APAC)",
            url: "/chapter-2",
          },
          {
            text: "CHAPTER FOUR",
            label: "February 29, 2024 | Riyadh (MENA)",
            url: "/chapter-4",
          },
          {
            text: "CHAPTER Five",
            label: "October 11, 2024 | Dubai",
            url: "/chapter-5",
          },
        ],
      },
      {
        id: 4,
        name: "Chapter 4",
        "md-title": "",
        route: "chapter-4",
        "sheet-url": `${baseURL}/chapter/4`,
        address: {
          line1: "Crowne Plaza Riyadh Minhal",
          line2: "Riyadh - Saudi Arabia",
          location:
            "https://www.google.com/maps/place/Crowne+Plaza+Riyadh+Minhal,+an+IHG+Hotel/@24.6895724,46.7169759,19z/data=!4m9!3m8!1s0x3e2f02e63805bb97:0xdcfe9325a432387!5m2!4m1!1i2!8m2!3d24.6896674!4d46.7173085!16s%2Fg%2F1xb2c0xr?entry=ttu",
        },
        date: "February 29, 2024",
        "tab-title": "Cracking IoT Conference - Disrupt-X | Intel ",
        buttons: [
          {
            text: "Chapter 1:",
            label: "November 22, 2022 |  Dubai (MENA)",
            url: "/chapter-1",
          },
          {
            text: "Chapter 2:",
            label: "June 13, 2023 | Malaysia (APAC)",
            url: "/chapter-2",
          },
          {
            text: "Chapter 3:",
            label: "October 13, 2023 | Dubai (MENA)",
            url: "/chapter-3",
          },
          {
            text: "Chapter 5:",
            label: "October 11, 2024 | Dubai",
            url: "/chapter-5",
          },
        ],
      },
      {
        id: 5,
        name: "Chapter 5",
        "md-title": "",
        route: "chapter-5",
        "sheet-url": `${baseURL}/chapter/5`,
        // "sheet-url": `https://script.google.com/macros/s/AKfycbyLABrMXafFpiB3Uz5Eqmh9un3IN3MsmpYfgkd0hrA0tbY5d-J1EOAy25Mly1CX4zMW/exec`,
        address: {
          line1: "Crowne Plaza Riyadh Minhal",
          line2: "Riyadh - Saudi Arabia",
          location:
            "https://www.google.com/maps/place/Crowne+Plaza+Riyadh+Minhal,+an+IHG+Hotel/@24.6895724,46.7169759,19z/data=!4m9!3m8!1s0x3e2f02e63805bb97:0xdcfe9325a432387!5m2!4m1!1i2!8m2!3d24.6896674!4d46.7173085!16s%2Fg%2F1xb2c0xr?entry=ttu",
        },
        date: "February 29, 2024",
        "tab-title": "Cracking IoT Conference - Disrupt-X | Intel ",
        buttons: [
          {
            text: "Chapter 1:",
            label: "November 22, 2022 |  Dubai (MENA)",
            url: "/chapter-1",
          },
          {
            text: "Chapter 2:",
            label: "June 13, 2023 | Malaysia (APAC)",
            url: "/chapter-2",
          },
          {
            text: "Chapter 3:",
            label: "October 13, 2023 | Dubai (MENA)",
            url: "/chapter-3",
          },
          {
            text: "Chapter 4:",
            label: "February 29, 2024 | Riyadh (MENA)",
            url: "/chapter-4",
          },
        ],
      },
    ],
    currentChapter: {},
    appData: undefined,
    breaks: [],
  },
  getters: {
    getAppData(state) {
      return state.appData;
    },
    getCurrentChapter(state) {
      return state.currentChapter;
    },
    getChapters(state) {
      return state.chapters;
    },
    getLoading(state) {
      return state.loading;
    },
    getDisPanel(state) {
      return state.dPPeople;
    },
    getGuestList(state) {
      return state.guestList;
    },
    getSpeakers(state) {
      return state.speakers;
    },
    getHeaderPeople(state) {
      return state.headerPeople;
    },
    otherDynamicData(state) {
      return state.otherDynamicData;
    },
    /**
     * @deprecated
     */
    getFirstBreak(state) {
      return state.breaks?.find((b) => b.id === 1);
    },
    /**
     * @deprecated
     */
    getSecondBreak(state) {
      return state.breaks?.find((b) => b.id === 2);
    },
    /**
     * @deprecated
     */
    getInitialBreak(state) {
      return state.breaks?.find((b) => b.id === 0);
    },
    /**
     * @deprecated
     */
    getNetworking(state) {
      return state.breaks?.find((b) => b["id-word"] === "id-networking");
    },
    /**
     * @deprecated
     */
    getCoffee(state) {
      return state.breaks?.find((b) => b["id-word"] === "id-coffee");
    },
    /**
     * @deprecated
     */
    getLunch(state) {
      return state.breaks?.find((b) => b["id-word"] === "id-lunch");
    },
    getAllPeople(getters) {
      return getters.getAppData
        ? [
            ...getPeople(getters.getAppData["header-people"], [
              ["name", "name"],
              ["avatar", "avatar"],
              ["miniAvatar", "miniAvatar"],
              ["position", "position"],
              ["company", "company"],
              ["country", "country"],
              ["personDescription", "personDescription"],
              ["video", "video"],
            ]),
          ]
        : [];
    },
    getGallery(state) {
      return {
        index: state.galleryIndex,
        imgs: state.galleryList,
        visible: state.galleryVisible,
      };
    },

    /**
     * Chapter 5
     */
    getBreaks(state) {
      return state.breaks;
    },
  },
  mutations: {
    setChapter(state, payload) {
      state.chapters.forEach((chapter) => {
        if (chapter["route"] === payload) {
          state.currentChapter = chapter;
        }
      });
    },
    setLoading(state, changes) {
      state.loading = changes;
    },
    setData(state, changes) {
      state.appData = changes;
    },
    setSpeakers(state, payload) {
      state.speakers = payload;
    },
    setOtherDynamicData(state, payload) {
      console.log(payload);
      try {
        let row =
          Array.isArray(payload) && payload.length > 0 ? payload[0] : undefined;
        state.otherDynamicData = {
          partnersHeadLogo: row.partnersHeadLogo,
          partnersList: JSON.parse(row.partnersList),
        };
      } catch (e) {
        console.log(e);
        state.otherDynamicData = {
          partnersHeadLogo: "",
          partnersList: [],
        };
      }
    },
    setDPPeople(state, payload) {
      state.dPPeople = payload;
    },
    setGuestList(state, payload) {
      state.guestList = payload;
    },
    setHeaderPeople(state, payload) {
      console.log("set", payload);
      state.headerPeople = payload;
    },
    setGalleryList(state, payload) {
      state.galleryList = payload;
    },
    setBreaks(state, payload) {
      state.breaks = payload;
    },
    showImgGallery(state, { imgs }) {
      state.galleryList = imgs;
      state.galleryVisible = true;
      state.galleryIndex = 0;
    },
    updateGalleryIndex(state, payload) {
      state.galleryIndex = payload;
    },
    updateGalleryVisible(state, payload) {
      state.galleryVisible = payload;
    },
  },
  actions: {
    async fetch({ commit, dispatch, state }) {
      try {
        // if (getters.getLoading) return;
        commit("setLoading", true);
        if (state.currentChapter["sheet-url"] === undefined) return;
        let res = await axios.get(state.currentChapter["sheet-url"]);

        // commit("setAppData", res.data.data);
        if (res.data.data) {
          dispatch("buildData", res.data.data);
          commit(
            "setOtherDynamicData",
            res.data.data.otherDynamicData != undefined
              ? res.data.data.otherDynamicData
              : {}
          );
        }
        commit("setLoading", false);
        return res;
      } finally {
        commit("setLoading", false);
      }
    },
    async buildData({ commit }, data) {
      commit("setData", data);

      let imgList = [];
      let imgIndex = 0; // start point of counting imgaes for gellery , record speakers firsr ,thes people ind dis panel then in gust list
      let speakers = await cook(
        data["speakers-time-periods"],
        data["speakers"],
        "time-period-id",
        "speakers_group",
        imgIndex,
        imgList
      );
      commit("setSpeakers", speakers["obj"]);
      let dPPeople = await cook(
        data["panel-discussion-time-periods"],
        data["people-by-panel-discussion"],
        "panel_discussion_id",
        "dis_panel_group",
        speakers["imgIndex"],
        speakers["imgList"]
      ); // speakers['imgIndex'] when count of speakers peapole is start count fo dis people
      commit("setDPPeople", dPPeople["obj"]);
      let guestList = await addImgIndex(
        data["guest-list"],
        dPPeople["imgIndex"],
        dPPeople["imgList"]
      );
      let breaks = data["breaks"];
      console.log(breaks);
      commit("setBreaks", breaks);
      commit("setGuestList", guestList["array"]);
      // commit('setGalleryList', guestList['imgList'])
      let headerPeople = getPeople(data["header-people"], [
        ["name", "name"],
        ["avatar", "avatar"],
        ["miniAvatar", "miniAvatar"],
        ["position", "position"],
        ["company", "company"],
        ["country", "country"],
        ["personDescription", "personDescription"],
        ["video", "video"],
      ]);
      commit("setHeaderPeople", headerPeople);
      console.log("header ", headerPeople);
    },
  },
};

let cook = function (
  parents,
  children,
  parentIdKey,
  parentTitle,
  imgIndex,
  imgList
) {
  let newObject = {};
  let count = Number(imgIndex);
  let imgs = [...imgList];
  parents.forEach((parent) => {
    if (parent["leadAvatar"]) {
      imgs.push({ src: parent["leadAvatar"], indexImg: count });
      parent["imgIndex"] = Number(count);
      count++;
    }
    if (parent.gallery?.length) {
      parent.galleryImgs = parent.gallery
        .split(",")
        .map((url) => ({ src: url }));
    }
    newObject[`${parentTitle}_${parent.id}`] = { children: [], ...parent };
  });
  children.forEach((child) => {
    imgs.push({ src: child["avatar"], indexImg: count });
    child["imgIndex"] = Number(count);
    if (child.gallery?.length) {
      child.galleryImgs = child.gallery.split(",").map((url) => ({ src: url }));
    }
    newObject[`${parentTitle}_${child[parentIdKey]}`]?.["children"].push(child);
    count++;
  });
  console.log(newObject, "object");
  return { obj: newObject, imgIndex: count, imgList: imgs };
};

let getPeople = function (array, mappArray) {
  let newArray = [];
  array.forEach((el) => {
    let obj = {};
    for (let i = 0; i < mappArray.length; i++) {
      const arr = mappArray[i];
      obj[arr[1]] = el[arr[0]];
    }
    newArray.push(obj);
  });
  return newArray;
};
let addImgIndex = function (array, imgIndex, imgList) {
  let imgs = [...imgList];

  let count = Number(imgIndex);
  array.forEach((el) => {
    imgs.push({ src: el["avatar"], indexImg: count });
    el["imgIndex"] = Number(count);
    count++;
  });
  return { array: array, imgIndex: count, imgList: imgs };
};
