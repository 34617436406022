<template>
  <svg
    width="182"
    height="211"
    viewBox="0 0 182 211"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
  <path
      d="M1 53.527L91.169 1L181.338 53.527V157.176L91.169 209.235L1 157.176V53.527Z"
      stroke="#00000000"
      stroke-width="1"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
