import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/chapter-5",
    component: () => import("@/app/App.vue"),
    children: [
      {
        path: "admin",
        component: () => import("@/layout/admin.vue"),
      },
      {
        path: "chapter-4",
        component: () => import("@/layout/app.vue"),
        children: [
          {
            path: "",
            component: () => import("@/land/layouts/layout/index-layout.vue"),
            children: [
              {
                path: "",
                component: () => import("@/land/pages/Chapter4Page.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "chapter-5",
        component: () => import("@/layout/app.vue"),
        children: [
          {
            path: "",
            component: () => import("@/land/layouts/layout/index-layout.vue"),
            children: [
              {
                path: "",
                component: () =>
                  import("@/land/pages/Chapter5Page/Chapter5Page.vue"),
              },
            ],
          },
        ],
      },
      {
        path: ":chapter",
        component: () => import("@/layout/app.vue"),
        children: [
          {
            path: "",
            component: () => import("@/land/layouts/layout/index-layout.vue"),
            children: [
              {
                path: "",
                component: () => import("@/land/land-page.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
  routes,
});

export default router;
