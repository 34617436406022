<template>
  <div :class="full ? 'w-100 h-100': ''">
    <component :is="type"  :person="person" />
  </div>
</template> 
<script>
import svgOutline from './components/svg-outline/index-component.vue'
import svgFill from './components/svg-fill/index-component.vue'
import svgPerson from './components/svg-person/index-component.vue'
import svgAnonymous from './components/svg-anonymous/index-component.vue'
import svgSpecialLeft from './components/svg-special-left/index-component.vue'
import svgSpecialRight from './components/svg-special-right/index-component.vue'
import svgSpecialMd from './components/svg-special-md/index-component.vue'
import svgEmpty from './components/svg-empty/index-component.vue'
export default {
  name: 'x-bee',
  props: {
    type: {
      type: String,
    },
    person:{
      type: Object
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    'bee-outline': svgOutline,
    // eslint-disable-next-line vue/no-unused-components
    'bee-fill': svgFill,
    // eslint-disable-next-line vue/no-unused-components
    'bee-person': svgPerson,
    // eslint-disable-next-line vue/no-unused-components
    'bee-anonymous': svgAnonymous,
    // eslint-disable-next-line vue/no-unused-components
    'bee-special-left': svgSpecialLeft,
    // eslint-disable-next-line vue/no-unused-components
    'bee-special-right': svgSpecialRight,
    // eslint-disable-next-line vue/no-unused-components
    'bee-special-md': svgSpecialMd,
    // eslint-disable-next-line vue/no-unused-components
    'bee-empty': svgEmpty,
  },
  data() {
    return {
      beeId: 1,
      menu: false,
    }
  },

  // props: {
  //   linesBetween: {
  //     type: Boolean,
  //     default: () => true,
  //   },
  //   linesAround: {
  //     type: Boolean,
  //     default: () => false,
  //   },
  //   linesInside: {
  //     type: Boolean,
  //     default: () => true,
  //   },
  //   greenBackopacity: {
  //     type: [String, Number],
  //     default: () => 0.2,
  //   },
  //   anonymous: {
  //     type: Boolean,
  //     default: () => true,
  //   },
  //   image: {
  //     type: String,
  //     default: () => '',
  //   },
  //   clickable: {
  //     type: Boolean,
  //     default: () => false,
  //   },
  //   name: {
  //     type: String,
  //     default: () => '',
  //   },
  //   job: {
  //     type: String,
  //     default: () => '',
  //   },
  //   info: {
  //     type: String,
  //     default: () => '',
  //   },
  // },
  created() {
  },
  computed:{
    full(){
      if(this.type === 'bee-special-left' || this.type === 'bee-special-md' || this.type === 'bee-special-right') return true
      return false
    }
  },
  mounted() {
    this.beeId = this._uid
  },
}
</script>
<style>
  .w-100 {
    width:100%
  }
  .h-100 {
    height: 100%;
  }
</style>
