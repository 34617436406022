<template>
  <div class="mh-guest-person d-flex align-center justify-center">
    <div class="text-center">
      <div class="mb-3 d-flex justify-center">
        <div
          class="mh-guest-person-avatar"
          :class="model.name ? 'cursor-pointer' : ''"
        >
          <div class="mh-guest-person-avatar-model-parent">
            <div
              @click="request()"
              class="mh-guest-person-avatar-model-main-avatar"
            >
              <v-avatar :size="90">
                <v-img :src="model.avatar"></v-img>
              </v-avatar>
            </div>
            <div class="mh-guest-person-avatar-model-mini-avatar">
              <v-avatar :size="30">
                <v-img :src="model.miniAvatar"></v-img>
              </v-avatar>
            </div>
            <div class="mh-dis-person-avatar-model-mini-avatar-gallery">
              <!-- <v-avatar
                @click="showImgGallery(imgIndex)"
                class="mh-dis-person-avatar-model-mini-avatar-gallery--pic cursor-pointer"
                :size="25"
                outlined
              >
                <v-img :src="gelleryButtonImg"></v-img>
              </v-avatar> -->
            </div>
          </div>
        </div>
      </div>

      <div class="mh-guest-person-name text-title-1">
        {{ model.name }}
      </div>
      <div class="mh-guest-person-position text-caption">
        {{ model.position }}
        <span v-if="model.company"> at </span>
        {{ model.company }}
      </div>
      <div class="mh-guest-person-country text-body-2">
        {{ model.country }}
      </div>
    </div>
  </div>
</template>

<script>
import avatarD from "@/assets/user-cyrcle.svg";
import { mapMutations } from "vuex";
import watchVideo from "@/mixins/watchVideo.js";
import gelleryButtonImg from "@/assets/gallery-button.svg";

export default {
  name: "mh-guest-person",
  mixins: [watchVideo],
  props: {
    defaultModel: {
      type: Boolean,
      default: () => false,
    },
    imgIndex: { type: Number },

    avatar: {
      type: String,
      default: () => avatarD,
    },
    miniAvatar: {
      type: String,
      default: () => "",
    },
    name: {
      type: String,
      default: () => "",
    },
    position: {
      type: String,
      default: () => "",
    },
    company: {
      type: String,
      default: () => "",
    },
    country: {
      type: String,
      default: () => "",
    },
    personDescription: {
      type: String,
    },
    video: {
      type: String,
    },
  },
  data() {
    return {
      gelleryButtonImg,
      defaultModelObj: {
        avatar:
          "https://i.ibb.co/jzJRWN9/101138716e268c39e49b77a158bce102-2.png",
        miniAvatar: "https://i.ibb.co/d6pck8X/Group-162500.png",
        name: "Mr. Nishat Chida",
        position: "Head Of Assets",
        company: "WASL Properties",
        country: "UAE",
        personDescription:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
      },
    };
  },
  computed: {
    model: {
      get() {
        if (this.defaultModel) return this.defaultModelObj;
        return {
          avatar: this.avatar || avatarD,
          miniAvatar: this.miniAvatar,
          name: this.name,
          position: this.position,
          company: this.company,
          country: this.country,
          personDescription: this.personDescription,
          video: this.video,
        };
      },
    },
  },
  methods: {
    ...mapMutations("Db", ["showImgGallery", "hideImgGallery"]),

    ...mapMutations("person-details-store", ["setDialog", "setPerson"]),
    request() {
      if (this.model.name) {
        this.setPerson({
          name: this.model.name,
          position: this.model.position,
          avatar: this.model.avatar,
          miniAvatar: this.model.miniAvatar,
          company: this.model.company,
          country: this.model.country,
          personDescription: this.model.personDescription,
          video: this.model.video,
        });
        this.setDialog(true);
      }
    },
  },
};
</script>

<style lang="scss">
.mh-guest-person {
  .mh-guest-person {
    border: 4px solid var(--v-base-light-base);
  }
  .mh-guest-person-name {
    color: var(--v-white-base);
  }
  .mh-guest-person-position {
    color: var(--v-white-base);
  }
  .mh-guest-person-company {
    color: var(--v-white-base);
  }
  .mh-guest-person-country {
    color: var(--v-primary-base);
  }
}

.mh-guest-person-avatar-model-parent {
  position: relative;
  z-index: 1;
  .mh-guest-person-avatar-model-main-avatar {
    position: relative;
    z-index: 2;
  }
  .mh-guest-person-avatar-model-mini-avatar {
    position: absolute;
    z-index: 3;
    // pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
  }
  .mh-guest-person-avatar-model-mini-avatar-pointer {
    pointer-events: fill;
  }
  .mh-dis-person-avatar-model-mini-avatar-gallery {
    position: absolute;
    z-index: 4;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: start;
    .mh-dis-person-avatar-model-mini-avatar-gallery--pic {
      pointer-events: fill;
      transform: translateX(-11px);
    }
  }
}

@media (min-width: 600px) {
}
@media (min-width: 960px) {
}
@media (min-width: 1264px) {
}
@media (min-width: 1904px) {
  .mh-guest-person {
    .mh-guest-person-name {
    }
    .mh-guest-person-position {
    }
    .mh-guest-person-company {
    }
    .mh-guest-person-country {
    }
  }
}
</style>
