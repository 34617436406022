<template>
  <div class="mh-dis-person d-flex align-center justify-center">
    <div class="d-flex flex-column align-center">
      <div class="mb-3">
        <div class="mh-dis-person-avatar">
          <div class="mh-dis-person-avatar-model-parent">
            <div
              class="mh-dis-person-avatar-model-main-avatar"
              :class="model.name ? 'cursor-pointer' : ''"
            >
              <v-avatar
                @click="
                  hasVideo(model.video) ? externalLink(model.video) : request()
                "
                style="border: 5px solid var(--v-base-light-base)"
                :size="getAvatarSize"
              >
                <v-img :src="model.avatar"></v-img>
              </v-avatar>
            </div>
            <div class="mh-dis-person-avatar-model-mini-avatar">
              <v-avatar :size="getMiniAvatarSize">
                <v-img :src="model.miniAvatar"></v-img>
              </v-avatar>
            </div>
            <div class="mh-dis-person-avatar-model-mini-avatar-gallery">
              <v-avatar
                v-if="model.galleryImgs"
                @click="
                  showImgGallery({ imgs: model.galleryImgs, index: imgIndex })
                "
                class="mh-dis-person-avatar-model-mini-avatar-gallery--pic cursor-pointer"
                :size="getMiniAvatarSize"
                outlined
              >
                <v-img :src="gelleryButtonImg"></v-img>
              </v-avatar>
            </div>
            <div class="mh-dis-person-avatar-model-request-button">
              <v-btn
                v-if="false && !hasVideo(model.video)"
                style="
                  font-size: 9px;
                  padding: 6px !important;
                  height: auto;
                  margin-bottom: 0px;
                  pointer-events: fill;
                "
                @click="
                  hasVideo(model.video)
                    ? externalLink(model.video)
                    : requestMeet()
                "
                small
                dark
                depressed
                color="primary"
              >
                <v-icon
                  class="mr-1"
                  small
                  v-if="hasVideo(model.video)"
                  color="white"
                  >mdi-youtube</v-icon
                >
                {{ submitLabel(model.video) }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mh-dis-person-name">
        {{ model.name }}
      </div>
      <div class="text-center mh-dis-person-position">
        {{ model.position }}
      </div>
      <div class="text-center mh-dis-person-company">
        {{ model.company }}
      </div>
      <div class="text-center mh-dis-person-country">
        {{ model.country }}
      </div>
    </div>
  </div>
</template>

<script>
import avatarD from "@/assets/user-cyrcle.svg";
import { mapMutations, mapGetters } from "vuex";
import watchVideo from "@/mixins/watchVideo.js";
import gelleryButtonImg from "@/assets/gallery-button.svg";
export default {
  name: "mh-dis-person",
  mixins: [watchVideo],
  props: {
    defaultModel: {
      type: Boolean,
      default: () => false,
    },
    imgIndex: {
      type: Number,
    },
    avatar: {
      type: String,
      default: () => avatarD,
    },
    miniAvatar: {
      type: String,
    },
    name: {
      type: String,
      default: () => "",
    },
    position: {
      type: String,
      default: () => "",
    },
    company: {
      type: String,
      default: () => "",
    },
    country: {
      type: String,
      default: () => "",
    },
    personDescription: {
      type: String,
    },
    video: {
      type: String,
    },
    galleryImgs: {
      type: Array,
    },
  },
  data() {
    return {
      gelleryButtonImg,
      defaultModelObj: {
        avatar:
          "https://i.ibb.co/jzJRWN9/101138716e268c39e49b77a158bce102-2.png",
        miniAvatar: "https://i.ibb.co/d6pck8X/Group-162500.png",
        name: "Name Here",
        position: "Head Of Assets",
        company: "Unabiz",
        country: "France",
        personDescription:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
      },
    };
  },
  computed: {
    ...mapGetters("Db", ["getCurrentChapter"]),
    getAvatarSize() {
      if (this.$vuetify.breakpoint.name === "xl") return 319;
      if (this.$vuetify.breakpoint.name === "lg") return 164;
      return 136;
    },
    getMiniAvatarSize() {
      if (this.$vuetify.breakpoint.name === "xl") return 84;
      if (this.$vuetify.breakpoint.name === "lg") return 40;
      return 40;
    },
    model: {
      get() {
        if (this.defaultModel) return this.defaultModelObj;
        return {
          avatar: this.avatar,
          miniAvatar: this.miniAvatar,
          name: this.name,
          position: this.position,
          company: this.company,
          country: this.country,
          personDescription: this.personDescription,
          video: this.video,
          galleryImgs: this.galleryImgs,
        };
      },
    },
  },
  methods: {
    ...mapMutations("Db", ["showImgGallery", "hideImgGallery"]),
    ...mapMutations("person-details-store", ["setDialog", "setPerson"]),
    ...mapMutations("meet-request-store", {
      setDialogMeet: "setDialog",
      setPersonMeet: "setPerson",
    }),
    requestMeet() {
      this.setPersonMeet({
        name: this.model.name,
        info: this.model.personDescription,
        job: this.model.position,
        image: this.model.avatar,
        video: this.model.video,
      });
      this.setDialogMeet(true);
    },
    request() {
      if (this.model.name) {
        this.setPerson({
          name: this.model.name,
          position: this.model.position,
          avatar: this.model.avatar,
          miniAvatar: this.model.miniAvatar,
          company: this.model.company,
          country: this.model.country,
          personDescription: this.model.personDescription,
          video: this.model.video,
        });
        this.setDialog(true);
      }
    },
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.mh-dis-person {
  .mh-dis-person {
    border: 4px solid var(--v-base-light-base);
  }
  .mh-dis-person-name {
    color: var(--v-white-base);
    font-weight: 500;
    font-size: 30px;
  }
  .mh-dis-person-position {
    color: var(--v-white-base);
  }
  .mh-dis-person-company {
    color: var(--v-white-base);
  }
  .mh-dis-person-country {
    color: var(--v-primary-base);
  }
}

.mh-dis-person-avatar-model-parent {
  position: relative;
  z-index: 1;
  .mh-dis-person-avatar-model-main-avatar {
    position: relative;
    z-index: 2;
  }
  .mh-dis-person-avatar-model-mini-avatar {
    position: absolute;
    z-index: 3;
    pointer-events: none;
    // pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
  }
  .mh-dis-person-avatar-model-mini-avatar-gallery {
    position: absolute;
    z-index: 4;
    pointer-events: none;
    // pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: start;
    .mh-dis-person-avatar-model-mini-avatar-gallery--pic {
      pointer-events: fill;
    }
  }
  .mh-dis-person-avatar-model-request-button {
    position: absolute;
    z-index: 3;
    pointer-events: none;
    // pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .mh-dis-person-avatar-model-mini-avatar-pointer {
    pointer-events: fill;
  }
}

@media (min-width: 600px) {
}
@media (min-width: 960px) {
}
@media (min-width: 1264px) {
  .mh-dis-person-avatar-model-parent {
    .mh-dis-person-avatar-model-mini-avatar-gallery {
      .mh-dis-person-avatar-model-mini-avatar-gallery--pic {
        transform: translateX(-15px) translateY(-25px);
      }
    }
  }
}
@media (min-width: 1904px) {
  .mh-dis-person {
    .mh-dis-person-name {
      font-size: 34px;
    }
    .mh-dis-person-position {
      font-size: 22px;
    }
    .mh-dis-person-company {
      font-size: 22px;
    }
    .mh-dis-person-country {
      font-size: 22px;
    }
  }
  .mh-dis-person-avatar-model-parent {
    .mh-dis-person-avatar-model-mini-avatar-gallery {
      .mh-dis-person-avatar-model-mini-avatar-gallery--pic {
        transform: translateX(-34px) translateY(-56px);
      }
    }
  }
}
</style>
