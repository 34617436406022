<template>
  <v-container class="x-speaker-section pb-14">
    <v-sheet
      style="background-color: transparent !important ; border: none !important"
      max-width="1050"
      class="mx-auto"
    >
      <v-row v-if="!hideTime">
        <v-col>
          <div class="ps-3 mb-2">
            <div class="d-flex align-center">
              <div class="me-4">
                <v-icon style="font-size: 32px" color="primary"
                  >mdi-calendar-month-outline</v-icon
                >
              </div>
              <div class="text-title-1 text-md-h6 white--text">
                {{ model.time }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <mh-speakers-list v-if="!noList">
        <slot></slot>
      </mh-speakers-list>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "x-speaker-section",
  props: {
    defaultModel: {
      type: Boolean,
      default: () => false,
    },
    time: {
      type: String,
    },
    hideTime: {
      type: Boolean,
      default: false,
    },
    noList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultModelObj: {
        time: "15:30 - 15:50",
      },
    };
  },
  computed: {
    model: {
      get() {
        if (this.defaultModel) return this.defaultModelObj;
        return {
          time: this.time,
        };
      },
    },
  },
};
</script>

<style lang="scss">
.x-speaker-section {
  .x-speaker-card {
    margin-bottom: 20px;
  }
  .x-speaker-card:last-child {
    margin-bottom: 0;
  }
}
</style>
