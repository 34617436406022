var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-10"},[_c('v-container',[_c('v-row',{staticClass:"mb-10"},[_c('v-col',{staticClass:"order-2",class:{ 'order-md-1': _vm.getCurrentChapter.id !== 2 },attrs:{"cols":"12","md":"auto","xl":_vm.getCurrentChapter.id === 2 ? '5' : 'auto',"offset-md":"1"}},[_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex justify-center justify-sm-start d-sm-block",attrs:{"cols":"12","sm":"auto"}},[_c('div',{staticClass:"x-speaker-person-avatar",class:_vm.model.leadName ? 'cursor-pointer' : ''},[_c('div',{staticClass:"x-speaker-person-avatar-model-parent"},[_c('div',{staticClass:"x-speaker-person-avatar-model-main-avatar"},[_c('v-avatar',{staticStyle:{"border":"5px solid var(--v-base-light-base)"},attrs:{"size":_vm.getAvatarSize},on:{"click":function($event){return _vm.request()}}},[_c('v-img',{attrs:{"src":_vm.model.leadAvatar}})],1)],1),_c('div',{staticClass:"x-speaker-person-avatar-model-mini-avatar",staticStyle:{"pointer-events":"none"}},[_c('v-avatar',{attrs:{"size":_vm.getMiniAvatarSize}},[_c('v-img',{attrs:{"src":_vm.model.leadMiniAvatar}})],1)],1),_c('div',{staticClass:"mh-dis-person-avatar-model-mini-avatar-gallery"},[(_vm.model.galleryImgs)?_c('v-avatar',{staticClass:"mh-dis-person-avatar-model-mini-avatar-gallery--pic cursor-pointer",attrs:{"size":_vm.getMiniAvatarSize,"outlined":""},on:{"click":function($event){return _vm.showImgGallery({
                          imgs: _vm.model.galleryImgs,
                          index: _vm.imgIndex,
                        })}}},[_c('v-img',{attrs:{"src":_vm.gelleryButtonImg}})],1):_vm._e()],1),_c('div',{staticClass:"x-dis-section-avatar-model-request-button"},[(
                        false && !_vm.hasVideo(_vm.model.video)
                      )?_c('v-btn',{staticStyle:{"font-size":"9px","padding":"6px !important","height":"auto","margin-bottom":"0px","pointer-events":"fill"},attrs:{"small":"","dark":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.hasVideo(_vm.model.video)
                          ? _vm.externalLink(_vm.model.video)
                          : _vm.requestMeet()}}},[(_vm.hasVideo(_vm.model.video))?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-youtube")]):_vm._e(),_vm._v(" "+_vm._s(_vm.submitLabel(_vm.model.video))+" ")],1):_vm._e()],1)])])]),_c('v-col',{staticClass:"text-center text-sm-start",attrs:{"cols":"12","sm":"auto"}},[_c('div',{staticClass:"white--text py-1",class:_vm.getCurrentChapter.id === 2
                    ? 'font-weight-bold text-sm-h5'
                    : 'text-h5 text-md-h4'},[_vm._v(" "+_vm._s(_vm.model.leadName)+" ")]),_c('div',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.model.leadPosition))]),_c('div',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.model.leadCompany))]),_c('div',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.model.leadCountry))])])],1)],1)]),_c('v-col',{staticClass:"order-1 ms-md-auto",class:{ 'order-md-2': _vm.getCurrentChapter.id !== 2 },attrs:{"cols":"12","md":_vm.getCurrentChapter.id === 2 ? '6' : '4',"xl":_vm.getCurrentChapter.id === 2 ? '5' : '3'}},[_c('div',[_c('div',{staticClass:"primary--text d-flex align-center mb-2 text-h6 text-md-h5"},[_c('div',{staticClass:"me-2"},[_c('v-icon',{staticClass:"text-h6 text-md-h4",attrs:{"color":"primary"}},[_vm._v("mdi-clock-outline")])],1),_c('div',[_vm._v(_vm._s(_vm.model.time))])]),_c('div',{staticClass:"white--text mb-1 text-title text-md-h6"},[_vm._v(" Panel "),_c('span',{staticClass:"primary--text"},[_vm._v("Discussion")])]),_c('div',{staticClass:"white--text mb-1",class:_vm.getCurrentChapter.id === 2
                ? 'text-h5 text-md-h4'
                : 'text-title text-md-h5'},[_vm._v(" "+_vm._s(_vm.model.title)+" ")]),_c('div',{staticClass:"white--text text-caption mb-3"},_vm._l((_vm.model.description),function(line,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(line)+" ")])}),0)])])],1),_c('v-row',[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }