export default {
  namespaced: true,
  state: {
    name: "app-store-global",
    screenSize: undefined
  },
  getters: {
    name(state) {
      return state.name;
    },
    screenSize(state) {
      return state.screenSize;
    },
  },
  mutations: {
    setName(state, payload) {
      state.name = payload;
    },
    changeScreenSize(state, payload) {
      state.screenSize = payload
    }
  },
  actions: {
    chagneName({ commit }, newName) {
      commit("setName", newName);
    },
  },
};
