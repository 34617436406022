<template>
  <v-dialog
    @keydown.39="indexModel++"
    @keydown.37="indexModel--"
    v-model="visibleModel"
    scrollable
    max-width="1150"
  >
    <v-card
      dark
      class="rouned-lg"
      style="border-radius: 30px"
      color="base-light-darken"
      tile
    >
      <v-system-bar height="42" color="base-light-darken" class="py-2">
        <v-spacer> </v-spacer>
        <v-icon large @click="visibleModel = false">mdi-close</v-icon>
      </v-system-bar>
      <v-main>
        <v-container>
          <v-row
            justify="center"
            v-touch="{
              left: () => swipe('Left'),
              right: () => swipe('Right'),
              up: () => swipe('Up'),
              down: () => swipe('Down'),
            }"
          >
            <v-col cols="10">
              <v-img
                v-if="images[indexModel]"
                style="height: calc(100vh - 310px)"
                contain
                :src="images[indexModel]['src']"
              >
              </v-img>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12 px-0">
              <v-slide-group
                @click="() => null"
                v-model="indexModel"
                class="pa-4 mh-gallery--slider"
                mandatory
                show-arrows
              >
                <v-slide-item
                  v-for="(img, i) in images"
                  :key="i"
                  v-slot="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'primary' : ''"
                    class="rouned-g mh-gallery--slider-card"
                    :class="
                      active
                        ? 'mh-gallery-img-line-active '
                        : 'mh-gallery--slider-card'
                    "
                    @click="toggle"
                  >
                    <v-img
                      :src="img['src'] ? img['src'] : img"
                      width="100%"
                      height="100%"
                      cover
                    >
                    </v-img>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "mh-gallery",
  props: {
    index: {
      type: Number,
      default: () => 0,
    },
    visible: {
      type: Boolean,
      default: () => false,
    },
    images: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {};
  },
  watch: {
    visible(value) {
      if (value) {
        document.addEventListener("backbutton", this.close, false);
      } else {
        document.removeEventListener("backbutton", this.close);
      }
    },
  },
  computed: {
    indexModel: {
      get() {
        return this.index;
      },
      set(newValue) {
        this.$emit("updateIndex", newValue);
      },
    },
    visibleModel: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit("updateVisible", newValue);
      },
    },
  },
  methods: {
    close() {
      this.indexModel = 0;
      this.visibleModel = false;
    },

    swipe(dir) {
      switch (dir) {
        case "Left":
          this.indexModel++;
          break;
        case "Right":
          this.indexModel--;
          break;
        case "Down":
          this.indexModel = 0;
          this.visibleModel = false;
          break;
        case "Up":
          this.indexModel = 0;
          this.visibleModel = false;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.mh-gallery-img-line-active {
  border: 1px solid var(--v-primary-base);
}
.mh-gallery {
  .left-arrow {
  }
}
.mh-gallery--slider-card {
  height: 30px;
  width: 30px;
  margin: 5px;
}
.mh-gallery--slider {
  .v-slide-group__prev {
    display: none;
  }
  .v-slide-group__next {
    display: none;
  }
}

@media (min-width: 600px) {
  .mh-gallery--slider-card {
    height: 70px;
    width: 70px;
    margin: 15px;
  }
  .mh-gallery--slider {
    .v-slide-group__prev {
      display: flex;
      .v-icon {
        border-radius: 24px;
        background: white;
        color: black !important;
        border-radius: 24px;
        background: white;
        color: black !important;
        // transform: translateX(23px);
        z-index: 999;
        font-size: 26px;
      }
    }
    .v-slide-group__next {
      display: flex;
      .v-icon {
        border-radius: 24px;
        background: white;
        color: black !important;
        border-radius: 24px;
        background: white;
        color: black !important;
        // transform: translateX(-23px);
        z-index: 999;
        font-size: 26px;
      }
    }
    .v-slide-group__prev--disabled,
    .v-slide-group__next--disabled {
      .theme--dark.v-icon {
        border-radius: 24px;
        background: white;
        color: black !important;
        border-radius: 24px;
        background: white;
        color: black !important;
        opacity: 0.5;
      }
    }
  }
}
</style>
