<template>
  <svg
    width="182"
    height="211"
    viewBox="0 0 182 211"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 54.527L91.169 2L181.338 54.527V158.176L91.169 210.235L1 158.176V54.527Z"
      fill="#75B84016"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
