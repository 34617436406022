<template>
  <div class="x-bees-row" ><slot></slot></div>
</template>

<script>
export default {
    name:'x-bees-row'
}
</script>

<style lang="scss">

@media (min-width: 600px) {
}
@media (min-width: 960px) {
}
@media (min-width: 1264px) {
}
@media (min-width: 1904px) {
}
</style>
