<template>
  <div v-bind="$attrs" v-on="$listeners" class="x-bee-child">
    <transition name="scale" mode="out-in">
      <div style="width: 100%; height: 100%" v-show="show">
        <slot v-bind:person="person"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
let eye = {
  timeout: 0,
  reTime:3000
}
export default {
  name: 'x-bee-eye',
  data() {
    return {
      person: {},
      time: 5000,
      show: true,
    }
  },
  props: {
    eye:{
      type:Object,
      default:()=> eye
    },
    timeout: {
      type: Number,
      default: () => 0,
    },
    reTime: {
      type: Number,
      default: () => 3000,
    },
  },
  computed: {
    ...mapGetters('x-bees-store', ['people']),
  },
  methods: {
    ...mapActions('x-bees-store', ['getPerson']),
  },
  created() {
    this.getPerson().then((e) => {
      if (this.people[e] != undefined) {
        this.person = this.people[e]
      }
      setTimeout(() => {
        this.show = true
      }, 200)
    })
    setTimeout(() => {
      setInterval(() => {
        this.show = false
        this.getPerson().then((e) => {
          if (this.people[e] != undefined) {
            this.person = this.people[e]
          }
          setTimeout(() => {
            this.show = true
          }, 200)
        })
      }, this.eye.reTime)
    }, this.eye.timeout)
  },
}
</script>

<style>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.9s ease;
}
.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0);
}
</style>
