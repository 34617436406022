<template>
  <div class="mh-speakers-list">
    <div class="mh-speakers-list-relative">
      <div class="mh-speakers-list-line"></div>
      <div class="mh-speakers-list-point"></div>
      <div class="mh-speakers-list-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'mh-speakers-list'
}
</script>

<style lang="scss">
.mh-speakers-list-relative {
  position: relative;
  z-index: 3;
  padding-block: 18px;
  .mh-speakers-list-line {
  position: absolute;
  z-index: 4;
  width:3px;
  background-color: var(--v-primary-base);
  height:100%;
  left: 26px;
  top:0px;
  border-radius: 10px;
  }
  .mh-speakers-list-point {
    width: 8px;
    height: 7px;
    position: absolute;
    z-index: 4;
    background-color: var(--v-primary-base);
    left: 23px;
    border-radius: 10px;
    bottom: -3px;
  }
  .mh-speakers-list-content {
  position: relative;
  z-index: 5;
  }
}
@media (min-width: 600px) {

}
@media (min-width: 960px) {

}
@media (min-width: 1264px) {
}
@media (min-width: 1904px) {
}
</style>
