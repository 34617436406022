import axios from "axios"
import { writablesURL } from "./urls"
import { countries } from "./endpoints" 

// 
export async function  getCountries ()  {
    const url = writablesURL + countries
    const res = await axios.get(url)
    return res.data.response
}
