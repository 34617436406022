export default {
  methods: {
    externalLink(url, cb = (url) => console.warn('url is missing', url)) {
      url ? window.open(url, '_blank') : cb(url);
    },
  },
  computed: {
    submitLabel() {
      return (video) => (video ? 'Watch' : 'Request A Meeting');
    },
    hasVideo() {
      return (video) => (video ? true : false);
    },
  },
};
