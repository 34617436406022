import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      dark: {
        "primary": "#75b840",
        "primary-v2" : "#25e675",
        "dark-primary": "#42ac48",
        "base-dark": "#142129",
        "base-light": "#20313B",
        "base-light-darken": "#18262F",
        "dark-grey": "#0d151a",
        "white": "#ffffff",
        "dark-white": "#e2e2e2",
        "darken-color": "#0d171c",


      },
      light: {
        "primary": "#75b840",
        "dark-primary": "#42ac48",
        "primary-v2" : "#25e675",
        "base-dark": "#142129",
        "base-light": "#20313B",
        "base-light-darken": "#18262F",
        "dark-grey": "#0d151a",
        "white": "#ffffff",
        "dark-white": "#e2e2e2",
        "darken-color": "#0d171c",

      }
    },
  },
});
