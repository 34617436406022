<template>
  <div
    class="w-100 h-100 d-flex align-center justify-center svg-special-right"
    :class="[hoverIt ? 'hover-it' : '']"
  >
    <div class="svg-special-right-container">
      <div class="svg-special-right-content1">
        <div class="svg-avatar-right-text">
          <div class="white--text text-center">
            <div>C Level</div>
            <div>Executives</div>
          </div>
        </div>
      </div>
      <div class="svg-special-right-content2">
        <div
          class="svg-avatar-right-number-padding"
          :class="hasBorder ? 'has-border' : ''"
        >
          <div class="svg-avatar-right-number">
            <div class="primary--text text-h6">
              <number
                ref="number1"
                style="margin-inline-end: -4px"
                :from="0"
                :to="
                  getCurrentChapter.id === 3
                    ? '600'
                    : getCurrentChapter.id === 5
                    ? 800
                    : '300'
                "
                :format="theFormat"
                :duration="2.5"
                :delay="0"
                easing="Power0.easeOut"
                @complete="completed"
              />+
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    meta: {
      type: Object,
    },
  },
  data() {
    return {
      hoverIt: true,
      hasBorder: false,
    };
  },
  computed: {
    ...mapGetters("Db", ["getCurrentChapter"]),
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
    completed() {
      this.hoverIt = false;
      console.log("Animation ends!");
    },
  },

  created() {},

  updated() {},
  mounted() {
    this.$nextTick(() => {
      this.hasBorder = true;
    });
  },
};
</script>
<style lang="scss">
@keyframes slidein {
  0% {
    background-color: rgba(132, 223, 58, 0);
  }
  60% {
    background-color: rgba(132, 223, 58, 0.349);
  }

  100% {
    background-color: rgb(131, 223, 58);
  }
}
.svg-special-right {
  .svg-special-right-container {
    position: relative;
    width: 0;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    .svg-special-right-content1 {
      transform: translateY(22px);
      scale: 0.1;
      transition: all 0.2s ease-in;
      position: absolute;
      .svg-avatar-right-text {
        font-size: 12px;
      }
    }
    .svg-special-right-content2 {
      transition: all 0.3s ease-in;
      position: absolute;
      .svg-avatar-right-number-padding {
        border-radius: 100%;
        padding: 1px;
        &.has-border {
          animation-duration: 2s;
          animation-name: slidein;
          animation-direction: 750ms;
          animation-fill-mode: forwards;
        }
        .svg-avatar-right-number {
          transition: all 2s ease-out;
          width: 85px;
          height: 85px;
          background-color: var(--v-darken-color-base);
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &:hover,
  &.hover-it {
    .svg-special-right-content1 {
      scale: 1;
    }
    .svg-special-right-content2 {
      transform: translateY(-55px);

      transition: all 0.22s ease-out;
    }
  }
}

@media (min-width: 960px) {
}
@media (min-width: 1264px) {
}
@media (min-width: 1400px) {
}
</style>
