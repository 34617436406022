<template>
  <div class="x-bees-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "x-bees-container",
};
</script>

<style lang="scss">
$mobilepeace: 14px;
$mobilehalf: 31px;
$mobiletop: -15px;
$mobileshapeHeight: 67px;
$mobilebeeWidth: 62px;
$mobilebeeHeight: 40px;
$mobilewidth: 64px;

$at960peace: 21px; //
$at960half: 50px; //
$at960top: -24px; //
$at960shapeHeight: 111px; //
$at960beeWidth: 100px; //
$at960beeHeight: 64px; //
$at960width: 103px; //

$at1264peace: 21px; //
$at1264half: 50px; //
$at1264top: -24px; //
$at1264shapeHeight: 111px;
$at1264beeWidth: 100px; //
$at1264beeHeight: 64px; //
$at1264width: 103px; //

$at1400peace: 28px; //
$at1400half: 62px; //
$at1400top: -29px; //
$at1400shapeHeight: 137px;
$at1400beeWidth: 124px; //
$at1400beeHeight: 80px; //
$at1400width: 124px; //

// $at1400peace: 40px;//
// $at1400half: 90px; //
// $at1400top: -43px; //
// $at1400shapeHeight: 200px;
// $at1400beeWidth: 180px; //
// $at1400beeHeight: 115px; //
// $at1400width: 185px; //
// container
.x-bees-container {
  margin: auto;
  padding-block: $mobilepeace;
}
// row
.x-bees-row {
  display: flex;
  margin-block: $mobilepeace;
  &:nth-child(even) {
    padding-inline-start: $mobilehalf;
  }
}

// bee
.x-bee-grand {
  height: $mobilebeeHeight;
  width: $mobilebeeWidth;
  .x-bee-parent {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .x-bee-child {
    position: absolute;
    width: 100%;
    height: $mobileshapeHeight;
    top: $mobiletop;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
@media (min-width: 960px) {
  .x-bees-container {
    padding-block: $at960peace;
  }
  .x-bees-row {
    margin-block: $at960peace;
    &:nth-child(even) {
      padding-inline-start: $at960half;
    }
  }
  .x-bee-grand {
    height: $at960beeHeight;
    width: $at960beeWidth;
    .x-bee-child {
      height: $at960shapeHeight;
      top: $at960top;
    }
  }
}
@media (min-width: 1264px) {
  .x-bees-container {
    padding-block: $at1264peace;
  }
  .x-bees-row {
    margin-block: $at1264peace;
    &:nth-child(even) {
      padding-inline-start: $at1264half;
    }
  }
  .x-bee-grand {
    height: $at1264beeHeight;
    width: $at1264beeWidth;
    .x-bee-child {
      height: $at1264shapeHeight;
      top: $at1264top;
    }
  }
}
@media (min-width: 1400px) {
  .x-bees-container {
    padding-block: $at1400peace;
  }
  .x-bees-row {
    margin-block: $at1400peace;
    &:nth-child(even) {
      padding-inline-start: $at1400half;
    }
  }
  .x-bee-grand {
    height: $at1400beeHeight;
    width: $at1400beeWidth;
    .x-bee-child {
      height: $at1400shapeHeight;
      top: $at1400top;
    }
  }
}
</style>
