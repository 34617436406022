<template>
  <div class="x-speaker-card speaker-card">
    <div class="speaker-card-border">
      <div class="x-speaker-card-body speaker-card-body">
        <v-row>
          <v-col cols="auto">
            <div
              class="x-speaker-person-avatar d-flex flex-column align-center"
            >
              <div class="x-speaker-person-avatar-model-parent">
                <div class="x-speaker-person-avatar-model-main-avatar">
                  <v-avatar
                    style="border: 5px solid var(--v-base-light-base)"
                    :size="getAvatarSize"
                  >
                    <v-img :src="model.avatar"></v-img>
                  </v-avatar>
                </div>
                <div class="x-speaker-person-avatar-model-mini-avatar">
                  <v-avatar :size="getMiniAvatarSize">
                    <v-img :src="model.miniAvatar"></v-img>
                  </v-avatar>
                </div>
              </div>
              <div class="d-flex align-center justify-end flex-column pt-3">
                <v-btn
                  v-if="model.galleryImgs"
                  @click="
                    showImgGallery({ imgs: model.galleryImgs, index: imgIndex })
                  "
                  outlined
                  color="white"
                  :x-small="$vuetify.breakpoint.name === 'xs'"
                  style="border-color: var(--v-primary-base) !important"
                >
                  VIEW GALLERY
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="auto" sm="8" md="4">
            <div class="white--text font-weight-bold text-sm-h5 py-1">
              {{ model.name }}
            </div>
            <div class="grey--text text--lighten--5 font-weight-light">
              {{ model.position }}
            </div>
            <div class="grey--text text--lighten--5 font-weight-light">
              {{ model.company }}
            </div>
            <div class="primary--text font-weight-light mb-2">
              {{ model.country }}
            </div>
            <div
              class="d-none font-weight-light d-sm-block white--text text-caption"
              style="11px !important"
            >
              {{ model.personDescription }}
            </div>
          </v-col>
          <v-col cols="12" class="d-sm-none py-0">
            <div class="white--text text-caption">
              {{ model.personDescription }}
            </div>
          </v-col>
          <v-col cols="12" md="5" class="ms-md-auto d-flex flex-column">
            <div
              class="primary--text d-flex align-center mb-2 text-h6 text-md-h5"
              v-if="model.time && model.time.length"
            >
              <div class="me-2">
                <v-icon class="text-h6 text-md-h4" color="primary"
                  >mdi-clock-outline</v-icon
                >
              </div>
              <div>{{ model.time }}</div>
            </div>
            <div class="white--text mb-2 text-title text-md-h5 text-bold">
              {{ model.eventTitle }}
            </div>
            <div class="grey--text text--lighten--5 font-weight-light">
              {{ model.eventSubTitle }}
            </div>
            <div
              class="white--text text-caption font-weight-light mb-3"
              style="font-size: 11px"
            >
              <div
                style="line-height: 20px"
                v-for="(line, index) in model.eventDescription"
                :key="index"
              >
                {{ line }}
              </div>
            </div>
            <div class="mt-auto">
              <v-btn
                v-if="false && !hasVideo(model.video)"
                style="font-size: 11px"
                @click="
                  hasVideo(model.video) ? externalLink(model.video) : request()
                "
                :small="
                  $vuetify.breakpoint.name === 'xs' ||
                  $vuetify.breakpoint.name === 'sm'
                "
                dark
                depressed
                color="primary"
              >
                <v-icon class="mr-1" v-if="hasVideo(model.video)" color="white"
                  >mdi-youtube</v-icon
                >
                {{ submitLabel(model.video) }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import simpelAvatar from "@/assets/user-cyrcle.svg";

import { mapMutations, mapGetters } from "vuex";
import watchVideo from "@/mixins/watchVideo.js";

export default {
  name: "x-speaker-card",
  mixins: [watchVideo],
  props: {
    defaultModel: {
      type: Boolean,
      default: () => false,
    },
    time: {
      type: String,
    },
    avatar: {
      type: String,
      default: () => simpelAvatar,
    },
    imgIndex: { type: Number },

    miniAvatar: {
      type: String,
    },
    personDescription: {
      type: String,
    },

    event: {
      type: String,
    },
    name: {
      type: String,
    },
    position: {
      type: String,
    },
    company: {
      type: String,
    },
    country: {
      type: String,
    },
    eventTitle: {
      type: String,
    },
    eventSubTitle: {
      type: String,
    },
    eventDescription: {
      type: String,
      default: () => "[]",
    },
    video: {
      type: String,
    },
    galleryImgs: {
      type: Array,
    },
  },
  data() {
    return {
      simpelAvatar,
      defaultModelObj: {
        avatar:
          "https://i.ibb.co/CmJkKkm/101138716e268c39e49b77a158bce102-3.png",
        miniAvatar: "https://i.ibb.co/d6pck8X/Group-162500.png",
        name: "Mr. Taha Khalifa",
        position: "GCC- Country Manager",
        company: "Intel",
        country: "MENA",
        personDescription:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
        time: "15:30 - 15:40",
        eventTitle: "IoT Implementation in Asia Pacific",
        eventSubTitle: "Trend",
        eventDescription: [
          "Asset Owners and FMs Requirement.",
          "One of Asia's Largest Public Listed FM company with Operation in Malaysia / Singapore /India /Indonesia /UAE /KSA/ Qatar and Taiwan",
        ],
      },
    };
  },

  computed: {
    ...mapGetters("Db", ["getCurrentChapter"]),
    getAvatarSize() {
      if (this.$vuetify.breakpoint.name === "xl") return 219;
      if (this.$vuetify.breakpoint.name === "lg") return 219;
      if (this.$vuetify.breakpoint.name === "sm") return 219;
      return 100;
    },
    getMiniAvatarSize() {
      if (this.$vuetify.breakpoint.name === "xl") return 64;
      if (this.$vuetify.breakpoint.name === "lg") return 64;
      if (this.$vuetify.breakpoint.name === "sm") return 64;
      return 30;
    },
    model: {
      get() {
        if (this.defaultModel) return this.defaultModelObj;
        return {
          avatar: this.avatar,
          miniAvatar: this.miniAvatar,
          name: this.name,
          position: this.position,
          company: this.company,
          country: this.country,
          personDescription: this.personDescription,
          time: this.time,
          eventTitle: this.eventTitle,
          eventSubTitle: this.eventSubTitle,
          eventDescription: JSON.parse(
            typeof this.eventDescription == "object"
              ? this.eventDescription
              : "[]"
          ),
          video: this.video,
          galleryImgs: this.galleryImgs,
        };
      },
    },
  },
  methods: {
    ...mapMutations("meet-request-store", ["setDialog", "setPerson"]),
    ...mapMutations("Db", ["showImgGallery", "hideImgGallery"]),

    request() {
      this.setPerson({
        name: this.model.name,
        info: this.model.personDescription,
        job: this.model.position,
        image: this.model.avatar,
      });
      this.setDialog(true);
    },
  },
};
</script>
<style lang="scss">
.x-speaker-person-avatar-model-parent {
  position: relative;
  z-index: 1;
  .x-speaker-person-avatar-model-main-avatar {
    position: relative;
    z-index: 2;
  }
  .x-speaker-person-avatar-model-mini-avatar {
    position: absolute;
    z-index: 3;
    // pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
  }
  .x-speaker-person-avatar-model-mini-avatar-pointer {
    pointer-events: fill;
  }
  .mh-dis-person-avatar-model-mini-avatar-gallery {
    position: absolute;
    z-index: 4;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: start;
    .mh-dis-person-avatar-model-mini-avatar-gallery--pic {
      pointer-events: fill;
      transform: translateX(-21px);
    }
  }
}
</style>
<style lang="scss">
.speaker-card {
  .speaker-card-border {
    border-radius: 14px;
    position: relative;
    z-index: 2;
    &::before {
      position: absolute;
      content: "";
      width: calc(100% + -3px);
      height: calc(100% + -14px);
      left: -3px;
      bottom: -3px;
      z-index: 1;
      background-color: var(--v-primary-base);
      border-radius: 14px;
    }
    .speaker-card-body {
      background-color: var(--v-base-dark-base);
      border-radius: 14px;
      position: relative;
      z-index: 2;

      .avatar-parent {
        background-color: var(--v-base-light-base);

        border-radius: 50%;
      }
      .paragraph-line-height {
        line-height: 27px;
      }
    }
  }
}

.x-speaker-card {
  .x-speaker-card-anonymous {
    pointer-events: none;
    user-select: none;
    filter: blur(12px);
  }
  .x-speaker-card-body {
    padding: 20px;
  }
  .x-speaker-card-avatar {
    padding: 10px;
  }
}
@media (min-width: 600px) {
  .x-speaker-card {
    .x-speaker-card-body {
      padding: 22px;
    }
  }
}
@media (min-width: 960px) {
  .x-speaker-card {
    .mh-speaker-card-avatar {
      padding: 10px;
    }
  }
}
@media (min-width: 1264px) {
}
@media (min-width: 1904px) {
}
</style>
