<template>
  <svg
    @click="openDialog"
    style="cursor: pointer"
    width="182"
    height="211"
    viewBox="0 0 182 211"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M91.2539 187.052V208.838" stroke="#75B840" />
    <path d="M162.812 64.9129L181.513 54.1689" stroke="#75B840" />
    <path
      d="M1 54.527L91.169 2L181.338 54.527V158.176L91.169 210.235L1 158.176V54.527Z"
      fill="#75B8402b"
    />
    <path d="M19.7 64.9153L1 54.1763" stroke="#75B840" />
    <path
      v-if="person.avatar"
      d="M19.3057 64.769L91.1657 24L163.026 64.769V146.288L91.1657 187.057L19.3057 146.288V64.769Z"
      stroke="#75B840"
      :fill="`url(#pattern0-1${beeId})`"
    />
    <defs xmlns="http://www.w3.org/2000/svg">
      <pattern
        v-if="person.avatar"
        :id="`pattern0-1${beeId}`"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          :xlink:href="`#image0_16_26${beeId}`"
          transform="translate(-0.0379827) scale(0.0139736 0.0121951)"
        />
      </pattern>
      <image
        :id="`image0_16_26${beeId}`"
        width="77"
        height="82"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :xlink:href="person.avatar"
      />
    </defs>
  </svg>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    person: {
      type: Object,
    },
  },

  data() {
    return {
      image:
        'https://i.pinimg.com/564x/4c/de/13/4cde13c1729eaf0fd050f531b22a76cd.jpg',
      beeId: 1,
    };
  },
  methods: {
    ...mapMutations('person-details-store', ['setDialog', 'setPerson']),
    openDialog() {
      if (this.person.name) {
        this.setPerson({
          name: this.person.name,
          position: this.person.position,
          avatar: this.person.avatar,
          miniAvatar: this.person.miniAvatar,
          company: this.person.company,
          country: this.person.country,
          personDescription: this.person.personDescription,
          video: this.person.video,
        });
        this.setDialog(true);
      }
    },
  },
  mounted() {
    this.beeId = this._uid;
  },
};
</script>

<style></style>
