<template>
  <v-dialog v-model="dialog" persistent width="500">
    <div class="dialogform">
      <div :class="[isChapterFour ? 'dialogform-container-v2' : '']" class="dialogform-container  auth-dialog-container">
        <div class="text-h4 mb-6">
          <span class="white--text">Request an </span>
          <span :class="[isChapterFour ? 'text-color-v2' : 'dark-primary--text']" class=" ">Invite</span>
        </div>
        <div>
          <v-form ref="form" v-model="valid" @submit.prevent="formSubmit">
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-text-field dense required small v-model="form.name" :rules="nameRules" outlined
                  class="radius mt-1 white--text" dark color="primary" label="Name *"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense required v-model="form.company" :rules="companyRules" outlined
                  class="radius mt-1 white--text" dark color="primary" label="Company *"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense required v-model="form.position" :rules="positionRules" outlined
                  class="radius mt-1 white--text" dark color="primary" label="Position *"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete @focus="onCountrySelectFocus" dense required v-model="countryObject"
                  :rules="positionRules" outlined item-text="name" return-object :items="countriesList"
                  class="radius mt-1 white--text" dark color="white" label="Country *">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Loading...
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field dense readonly required v-model="phoneCode" outlined class="radius mt-1 white--text" dark
                  color="white" label="Code "></v-text-field>
              </v-col>
              <v-col cols="9" class="ps-4 p">
                <v-text-field dense required v-model="phoneNumber" :rules="phoneRules" outlined
                  class="radius mt-1 white--text" dark color="white" label="Phone *"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="form.email" outlined class="radius mt-1 white--text" dark color="primary"
                  :rules="emailRules" required label="Email *"></v-text-field>
              </v-col>

              <v-col cols="12">
                <div class="d-flex">
                  <div class="primary--text font-kalam">{{ thanks }}</div>
                  <v-spacer></v-spacer>
                  <v-btn @click="
                    resetForm();
                  dialog = false;
                  " style="border-radius: 10px" dark outlined class="me-3"
                    :color="isChapterFour ? 'primary-v2' : 'dark-primary'">
                    Cancel
                  </v-btn>
                  <v-btn :loading="loader" @click="formSubmit()" style="border-radius: 10px" dark
                    :color="isChapterFour ? 'primary-v2' : 'dark-primary'">
                    Send
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
    <v-snackbar app absolute color="base-light" v-model="snackbar" :timeout="2000">
      <div v-for="(line, index) in errors" :key="index">
        {{ line }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { getCountries } from '@/api/api.service';
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  name: 'x-invite-request-dialog',
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    dialog(newVal) {
      this.thanks = '';
      this.$emit('input', newVal);
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
    phoneCode() {
      return this.countryObject.phoneCode ? this.countryObject.phoneCode : ''
    },
    country() {
      return this.countryObject.name ? this.countryObject.name : ''
    },
    ...mapGetters('Db', ['getCurrentChapter']),
    isChapterFour() {
      return this.getCurrentChapter.id === 4
    }
  },
  data() {
    return {
      thanks: '',
      valid: false,
      loader: false,
      form: {},
      errors: ['line one', 'line tow'],
      snackbar: false,
      nameRules: [(v) => !!v || 'Name is required'],
      companyRules: [(v) => !!v || 'Company is required'],
      positionRules: [(v) => !!v || 'Position is required'],
      phoneRules: [(v) => !!v || 'Phone is required', (v) => /^\d+$/.test(v) || 'Phone  must be a number'],

      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /\S+@\S+.\S+/.test(v) || 'E-mail must be valid',
      ],
      messageRules: [(v) => !!v || 'You must type smth '],
      countriesList: [],
      countryObject: {},
      phoneNumber: ''
    };
  },
  methods: {
    async formSubmit() {
      this.thanks = '';
      // this.form.country = this.country
      this.form.phone = `${this.phoneCode}${this.phoneNumber}`
      if (this.valid) {
        this.loader = true;
        try {
          if (this.form?.phone == '') this.form.phone = undefined;

          let res = await axios.post(
            'https://stage-back.disrupt-x.io/cracking-iot/api/invite',
            this.form
          );
          if (res.status === 200 || res.status === 201) {
            this.thanks = 'sent successfully ';
            this.form = {};
            this.countryObject = {}
            this.phoneNumber = ''
            this.$refs.form.resetValidation();
          } else {
            this.thanks = 'Failed, Please Send Again';
          }
          this.loader = false;
        } catch (err) {
          if ('response' in err && 'data' in err.response && 'msg' in err.response.data) {
            this.errors = [err.response.data.msg];
            this.snackbar = true;
          }
          this.loader = false;
        }
      } else {
        this.$refs.form.validate();
      }
    },
    resetForm() {
      this.form = {};
      this.$refs.form.resetValidation();
    },
    async onCountrySelectFocus() {
      this.countriesList = await getCountries()
    }
  },
};
</script>

<style lang="scss">
.auth-dialog-container {
  .v-text-field--outlined {
    border-radius: 8px;
  }

  .v-input__append-inner {
    margin-top: 12px;
  }
}

.dialogform-container {
  border-radius: 15px;
  // padding: 30px 30px;
  padding: 19px 21px;
  background-color: var(--v-base-light-base);
  box-shadow: 0 3px 9px rgb(0 0 0 / 30%);
  border: 2px solid var(--v-primary-base);
  background-color: #121e269c;
  border-radius: 14px;
  backdrop-filter: blur(11px);


}

.dialogform-container-v2 {
  border-radius: 15px;
  padding: 19px 21px;
  background-color: var(--v-base-light-base);
  box-shadow: 0 3px 9px rgb(0 0 0 / 30%);
  border: 2px solid var(--v-primary-base);
  background-color: #121e269c;
  border-radius: 14px;
  backdrop-filter: blur(11px);
  border-color: #25e675;
  border-style: solid;
}

@media (min-width: 600px) {}

@media (min-width: 960px) {}

@media (min-width: 1264px) {}

@media (min-width: 1904px) {}
</style>
