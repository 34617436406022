<template>
  <div class="pb-10">
    <v-container>
      <v-row class="mb-10">
        <v-col
          cols="12"
          md="auto"
          :xl="getCurrentChapter.id === 2 ? '5' : 'auto'"
          offset-md="1"
          :class="{ 'order-md-1': getCurrentChapter.id !== 2 }"
          class="order-2"
        >
          <div>
            <v-row align="center">
              <v-col
                cols="12"
                sm="auto"
                class="d-flex justify-center justify-sm-start d-sm-block"
              >
                <div
                  class="x-speaker-person-avatar"
                  :class="model.leadName ? 'cursor-pointer' : ''"
                >
                  <div class="x-speaker-person-avatar-model-parent">
                    <div class="x-speaker-person-avatar-model-main-avatar">
                      <v-avatar
                        @click="request()"
                        style="border: 5px solid var(--v-base-light-base)"
                        :size="getAvatarSize"
                      >
                        <v-img :src="model.leadAvatar"></v-img>
                      </v-avatar>
                    </div>
                    <div
                      class="x-speaker-person-avatar-model-mini-avatar"
                      style="pointer-events: none"
                    >
                      <v-avatar :size="getMiniAvatarSize">
                        <v-img :src="model.leadMiniAvatar"></v-img>
                      </v-avatar>
                    </div>
                    <div class="mh-dis-person-avatar-model-mini-avatar-gallery">
                      <v-avatar
                        v-if="model.galleryImgs"
                        @click="
                          showImgGallery({
                            imgs: model.galleryImgs,
                            index: imgIndex,
                          })
                        "
                        class="mh-dis-person-avatar-model-mini-avatar-gallery--pic cursor-pointer"
                        :size="getMiniAvatarSize"
                        outlined
                      >
                        <v-img :src="gelleryButtonImg"></v-img>
                      </v-avatar>
                    </div>
                    <div class="x-dis-section-avatar-model-request-button">
                      <v-btn
                        v-if="
                          false && !hasVideo(model.video)
                        "
                        style="
                          font-size: 9px;
                          padding: 6px !important;
                          height: auto;
                          margin-bottom: 0px;
                          pointer-events: fill;
                        "
                        @click="
                          hasVideo(model.video)
                            ? externalLink(model.video)
                            : requestMeet()
                        "
                        small
                        dark
                        depressed
                        color="primary"
                      >
                        <v-icon
                          class="mr-1"
                          v-if="hasVideo(model.video)"
                          color="white"
                          small
                          >mdi-youtube</v-icon
                        >
                        {{ submitLabel(model.video) }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="auto" class="text-center text-sm-start">
                <div
                  class="white--text py-1"
                  :class="
                    getCurrentChapter.id === 2
                      ? 'font-weight-bold text-sm-h5'
                      : 'text-h5 text-md-h4'
                  "
                >
                  {{ model.leadName }}
                </div>
                <div class="white--text">{{ model.leadPosition }}</div>
                <div class="white--text">{{ model.leadCompany }}</div>
                <div class="primary--text">{{ model.leadCountry }}</div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
          cols="12"
          :md="getCurrentChapter.id === 2 ? '6' : '4'"
          :xl="getCurrentChapter.id === 2 ? '5' : '3'"
          class="order-1 ms-md-auto"
          :class="{ 'order-md-2': getCurrentChapter.id !== 2 }"
        >
          <div>
            <div
              class="primary--text d-flex align-center mb-2 text-h6 text-md-h5"
            >
              <div class="me-2">
                <v-icon class="text-h6 text-md-h4" color="primary"
                  >mdi-clock-outline</v-icon
                >
              </div>
              <div>{{ model.time }}</div>
            </div>
            <div class="white--text mb-1 text-title text-md-h6">
              Panel <span class="primary--text">Discussion</span>
            </div>
            <div
              class="white--text mb-1"
              :class="
                getCurrentChapter.id === 2
                  ? 'text-h5 text-md-h4'
                  : 'text-title text-md-h5'
              "
            >
              {{ model.title }}
            </div>
            <div class="white--text text-caption mb-3">
              <div v-for="(line, index) in model.description" :key="index">
                {{ line }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <slot></slot>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import avatarD from "@/assets/user-cyrcle.svg";
import { mapMutations, mapGetters } from "vuex";
import watchVideo from "@/mixins/watchVideo.js";
import gelleryButtonImg from "@/assets/gallery-button.svg";
export default {
  name: "x-dis-section",
  mixins: [watchVideo],
  props: {
    defaultModel: {
      type: Boolean,
      default: () => false,
    },
    leadAvatar: {
      type: String,
      default: () => avatarD,
    },
    imgIndex: {
      type: Number,
    },
    leadMiniAvatar: {
      type: String,
    },
    leadName: {
      type: String,
    },
    leadPosition: {
      type: String,
    },
    leadCompany: {
      type: String,
    },
    leadCountry: {
      type: String,
    },
    time: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
      default: () => "[]",
    },
    personDescription: {
      type: String,
    },
    video: {
      type: String,
    },
    galleryImgs: {
      type: Array,
    },
  },
  data() {
    return {
      gelleryButtonImg,
      defaultModelObj: {
        leadAvatar:
          "https://i.ibb.co/CmJkKkm/101138716e268c39e49b77a158bce102-3.png",
        leadMiniAvatar: "https://i.ibb.co/d6pck8X/Group-162500.png",
        leadName: "Mr. Taha Khalifa",
        leadPosition: "GCC- Country Manager",
        leadCompany: "Intel",
        leadCountry: "MENA",
        time: "15:30 - 15:40",
        title: "IoT Implementation in Asia Pacific",
        description: [
          "Lorem ipsum dolor sit amet, consectet adop adipisicing elit, sed do eiusmod temporara. Another projects managed with a easy design ",
        ],
        personDescription:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy",
      },
    };
  },
  computed: {
    ...mapGetters("Db", ["getCurrentChapter"]),
    getAvatarSize() {
      if (this.$vuetify.breakpoint.name === "xl") return 319;

      if (this.$vuetify.breakpoint.name === "lg") return 184;
      return 184;
    },
    getMiniAvatarSize() {
      if (this.$vuetify.breakpoint.name === "xl") return 84;
      if (this.$vuetify.breakpoint.name === "lg") return 40;
      if (this.$vuetify.breakpoint.name === "sm") return 40;
      return 40;
    },
    model: {
      get() {
        if (this.defaultModel) return this.defaultModelObj;
        return {
          leadAvatar: this.leadAvatar,
          leadMiniAvatar: this.leadMiniAvatar,
          leadName: this.leadName,
          leadPosition: this.leadPosition,
          leadCompany: this.leadCompany,
          leadCountry: this.leadCountry,
          time: this.time,
          title: this.title,
          description: JSON.parse(this.description),
          personDescription: this.personDescription,
          video: this.video,
          galleryImgs: this.galleryImgs,
        };
      },
    },
  },
  methods: {
    ...mapMutations("person-details-store", ["setDialog", "setPerson"]),
    ...mapMutations("Db", ["showImgGallery", "hideImgGallery"]),

    ...mapMutations("meet-request-store", {
      setDialogMeet: "setDialog",
      setPersonMeet: "setPerson",
    }),
    requestMeet() {
      this.setPersonMeet({
        name: this.model.leadName,
        info: this.model.personDescription,
        job: this.model.leadPosition,
        image: this.model.leadAvatar,
        video: this.model.video,
      });
      this.setDialogMeet(true);
    },
    request() {
      if (this.model.leadName) {
        this.setPerson({
          name: this.model.leadName,
          position: this.model.leadPosition,
          avatar: this.model.leadAvatar,
          miniAvatar: this.model.leadMiniAvatar,
          company: this.model.leadCompany,
          country: this.model.leadCountry,
          personDescription: this.model.personDescription,
          video: this.model.video,
        });
        this.setDialog(true);
      }
    },
  },
};
</script>

<style lang="scss">
.x-dis-section-avatar-model-request-button {
  position: absolute;
  z-index: 3;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.mh-dis-person-avatar-model-mini-avatar-gallery {
  position: absolute;
  z-index: 4;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  .mh-dis-person-avatar-model-mini-avatar-gallery--pic {
    pointer-events: fill;
    transform: translateX(-21px);
  }
}
</style>
