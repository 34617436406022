<template>
  <v-dialog v-model="dialog" width="500">
    <div class="dialogform">
      <div class="x-book-request-dialog-component  auth-dialog-container">
        <div class="">
          <div class="d-flex justify-center mb-2">
            <div style="width: 70%;" class="text-center">
              <v-img :src="logo"></v-img>
            </div>

          </div>
          <div  class="x-book-request-dialog-title mb-2 text-center  ">
            Book Your Stand
          </div>
        </div>
        <div>
          <v-form ref="form" v-model="valid" @submit.prevent="formSubmit">
            <v-row dense no-gutters>
              <v-col cols="12">
                <v-text-field dense required small v-model="form.name" :rules="nameRules" outlined
                  class="text-filed-v2 radius mt-1 white--text" dark color="primary" label="Full Name *"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense required v-model="form.company" :rules="companyRules" outlined
                  class="text-filed-v2 radius mt-1 white--text" dark color="primary" label="Company Name *"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense required v-model="form.companyWebsite" :rules="companyWebsiteRules" outlined
                  class="text-filed-v2 radius mt-1 white--text" dark color="primary" label="Company Website *"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense required v-model="form.position" :rules="positionRules" outlined
                  class="text-filed-v2 radius mt-1 white--text" dark color="primary" label="Job Title *"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete @focus="onCountrySelectFocus" dense required v-model="countryObject"
                  :rules="positionRules" outlined item-text="name" return-object :items="countriesList"
                  class="text-filed-v2 radius mt-1 white--text" dark color="white" label="Country *">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Loading...
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field dense readonly required v-model="phoneCode" outlined
                  class="text-filed-v2 radius mt-1 white--text" dark color="white" label="Code "></v-text-field>
              </v-col>
              <v-col cols="9" class="ps-4 p">
                <v-text-field dense required v-model="phoneNumber" :rules="phoneRules" outlined
                  class=" text-filed-v2 radius mt-1 white--text" dark color="white" label="Phone *"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense v-model="form.email" outlined class="text-filed-v2 radius mt-1 white--text" dark
                  color="primary" :rules="emailRules" required label="Email *"></v-text-field>
              </v-col>

              <v-col cols="12">
                <div class="d-flex ">
                 


                  <v-btn class="mh-btn-chapter-v3" block :loading="loader" @click="formSubmit()"
                    style="border-radius: 10px">
                    Submit
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
    <v-snackbar app absolute color="base-light" v-model="snackbar" :timeout="2000">
      <div v-for="(line, index) in errors" :key="index">
        {{ line }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar  app absolute    color="base-light" v-model="snackbarThanks" :timeout="4000">
      <div class="text-color-v2">
        {{thanks}}
      </div>
      
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { getCountries } from '@/api/api.service';
import logo from '@/assets/logo-with-iot.svg';
import axios from 'axios';
export default {
  name: 'x-book-request-dialog',
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    dialog(newVal) {
      this.thanks = '';
      this.$emit('input', newVal);
    },
    thanks (nextValue){
      if(nextValue) {
            this.snackbarThanks = true;
      }
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
    phoneCode() {
      return this.countryObject.phoneCode ? this.countryObject.phoneCode : ''
    },
    country() {
      return this.countryObject.name ? this.countryObject.name : ''
    }
  },
  data() {
    return {
      thanks: '',
      valid: false,
      loader: false,
      form: {},
      errors: ['line one', 'line tow'],
      snackbar: false,
      nameRules: [(v) => !!v || 'Name is required'],
      companyRules: [(v) => !!v || 'Company is required'],
      companyWebsiteRules : [
        (v) => !!v || 'Company Website is required' ,
        (v) => /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(v) || "URL is not valid",
      ],
      positionRules: [(v) => !!v || 'Position is required'],
      phoneRules: [(v) => !!v || 'Phone is required', (v) => /^\d+$/.test(v) || 'Phone  must be a number'],

      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /\S+@\S+.\S+/.test(v) || 'E-mail must be valid',
      ],
      messageRules: [(v) => !!v || 'You must type smth '],
      countriesList: [],
      countryObject: {},
      phoneNumber: '',
      logo,
    };
  },
  methods: {
    async formSubmit() {
      this.thanks = '';
      // this.form.country = this.country
      this.form.phone = `${this.phoneCode}${this.phoneNumber}`
      if (this.valid) {
        this.loader = true;
        try {
          if (this.form?.phone == '') this.form.phone = undefined;

          let res = await axios.post(
            'https://stage-back.disrupt-x.io/cracking-iot/api/book',
            this.form
          );
          if (res.status === 200 || res.status === 201) {
            this.thanks = `Success! We've received your inquiry on stand opportunities. We'll be in touch soon. Thanks for your interest! `;
            this.form = {};
            this.countryObject = {}
            this.phoneNumber = ''
            this.$refs.form.resetValidation();
          } else {
            this.thanks = 'Failed, Please Send Again';
          }
          this.loader = false;
        } catch (err) {
          if ('response' in err && 'data' in err.response && 'msg' in err.response.data) {
            this.errors = [err.response.data.msg];
            this.snackbar = true;
          }
          this.loader = false;
        }
      } else {
        this.$refs.form.validate();
      }
    },
    resetForm() {
      this.form = {};
      this.$refs.form.resetValidation();
    },
    async onCountrySelectFocus() {
      this.countriesList = await getCountries()
    }
  },
 
};
</script>

<style lang="scss">
.auth-dialog-container {
  .v-text-field--outlined {
    border-radius: 8px;
  }

  .v-input__append-inner {
    margin-top: 12px;
  }
}



.x-book-request-dialog-component {
  border-radius: 15px;
  // padding: 30px 30px;
  padding: 19px 21px;
  background-color: var(--v-base-light-base);
  box-shadow: 0 3px 9px rgb(0 0 0 / 30%);
  // border: 2px solid var(--v-primary-base);
  // background-color: #121e269c;
  border-radius: 14px;
  // backdrop-filter: blur(11px);
  background-color: var(--v-base-light-base);

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
  }
  
}

.x-book-request-dialog-title {
  font-weight: bold;
  color: var(--v-white-base);
  font-size: 18px;
}

@media (min-width: 600px) {}

@media (min-width: 960px) {}

@media (min-width: 1264px) {}

@media (min-width: 1904px) {}
</style>
